import React from 'react';
import {
  Link,
  VStack,
  HStack,
  Container,
  Icon,
  Text,
} from '@chakra-ui/react';
import {
  FaTwitter,
  FaTelegram,
} from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

function Footer() {
  return (
    <Container mt={8}>
      <VStack>
        <HStack>
          <Link href="https://x.com/DreamLotusZen" isExternal>
            <Icon as={FaTwitter} boxSize={7} color="green.300" />
          </Link>
          <Link href="https://t.me/dreamlotusstudio" isExternal>
            <Icon as={FaTelegram} boxSize={7} color="green.300" />
          </Link>
          <Link href="mailto:info@dreamlotus.xyz">
            <Icon as={MdEmail} boxSize={7} color="green.300" />
          </Link>
          {/* <Icon as={FaTwitter} boxSize={6} color="green.300" /> */}
        </HStack>
        <Text as="i" color="teal.500" fontSize="xs">
          Copyright &copy; 2024 dreamlotus.xyz
        </Text>
      </VStack>
    </Container>
  );
}

export default Footer;
