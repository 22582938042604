import React from 'react';
import {
  Container,
  Text,
  VStack,
  Box,
  Icon,
  Link,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import HomeIcon from '../../components/views/HomeIcon';
import { AiTwotoneMail } from 'react-icons/ai';
import { GiLotusFlower, GiButterfly, GiRainbowStar } from "react-icons/gi";
import { FaStarAndCrescent, FaGem } from 'react-icons/fa';
import ContactCard from '../../resources/images/contact/contact-card.png';
import LotusGif from '../../resources/images/lotus.gif';
import LotusMoon from '../../resources/images/moonlotus.gif';
import VioletButterflyGif from '../../resources/images/violet-butterfly.gif';
import Rainbow from '../../resources/images/rainbow.gif';
function About() {
  const Info = {
    studio: {
      bgGradeint:
        'radial-gradient( circle farthest-corner at 12.3% 19.3%,  rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2% )',
      title: 'Digital Innovation with Artistry',
      description: `Dream Lotus Studio strives to innovate in the digital realm with
                advanced technology and captivating digital artistry.`,
      imgUrl: LotusGif,
      imgAlt: 'Lotus',
    },
    development: {
      bgGradeint:
        'radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )',
      title: 'Dedicated Full-Stack Development',
      description: `As a solo full-stack developer based in Canada, I am dedicated
                to infusing every project with a unique blend of curiosity,
                creativity, and technical expertise.`,
      imgUrl: LotusMoon,
      imgAlt: 'Butterflies',
    },
    science: {
      bgGradeint:
        'linear-gradient( 109.6deg,  rgba(48,207,208,1) 11.2%, rgba(51,8,103,1) 92.5% )',
      title: 'The Convergence of Neuroscience, Technology, and Digital Arts',
      description: `I hold a PhD in Neuroscience. Does this relate to coding and
                  digital arts? Absolutely. It provides me with a broad
                  perspective and deep understanding that enriches my approach
                  to both fields.`,
      imgUrl: VioletButterflyGif,
      imgAlt: 'Butterflies',
    },
    life: {
      bgGradeint:
        'linear-gradient( 109.6deg,  rgba(61,245,167,1) 11.2%, rgba(9,111,224,1) 91.1% )',
      title: 'Life Is Continuous Exploration',
      description: `After all, life is about
                  continual exploration and growth, a perpetual quest for
                  meaning and the inner light that illuminates our soul.`,
      imgUrl: Rainbow,
      imgAlt: 'Rainbow in the sky',
    },
  };
  const InfoCard = ({ obj }) => {
    return (
      <Box
        w={['385px', '488px', '580px']}
        boxShadow="2xl"
        p="6"
        rounded="md"
        bgGradient={obj.bgGradeint}
      >
        <Container>
          <VStack spacing={6}>
            <Box>
              <Text
                color="whiteAlpha.900"
                // color="green.200"
                fontSize="4xl"
                style={{ fontFamily: 'Lobster' }}
              >
                {obj.title}
              </Text>
            </Box>
            <Box>
              <Text fontSize="2xl" color="whiteAlpha.900">
                {obj.description}
              </Text>
            </Box>
            <Box mb={3}>
              <img src={obj.imgUrl} alt={obj.imgAlt} />
            </Box>
          </VStack>
        </Container>
      </Box>
    );
  };
  const RenderInfo = () => {
    return (
      <VStack spacing={6} mt={3}>
        <Icon as={GiLotusFlower} w={10} h={10} color="green.300" />
        <InfoCard obj={Info.studio} />
        <Icon as={FaStarAndCrescent} w={10} h={10} color="green.300" />
        <InfoCard obj={Info.development} />
        <Icon as={GiButterfly} w={10} h={10} color="green.300" />
        <InfoCard obj={Info.science} />
        <Icon as={GiRainbowStar} w={10} h={10} color="green.300" />
        <InfoCard obj={Info.life} />
      </VStack>
    );
  };
  const TextOnImage = () => {
    return (
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          color: 'black',
          marginTop: '30px',
        }}
      >
        <img src={ContactCard} alt="heart frame" style={{ width: '100%' }} />

        <div
          style={{
            position: 'absolute',
            top: '83%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Link href="mailto:info@dreamlotus.xyz">
            <Icon as={AiTwotoneMail} boxSize="88px" color="green.300" />
          </Link>
          <Text>info@dreamlotus.xyz</Text>
        </div>
      </div>
    );
  };

  return (
    <VStack>
      <HomeIcon />
      {/* <Box boxSize="md">
        <Image
          src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718649600/about_xko9x7.png"
          alt="about"
        />
      </Box> */}
      <Box>
        <Text
          color="green.400"
          fontSize={[33, 47, 58]}
          style={{ fontFamily: 'Lobster' }}
        >
          About
        </Text>
      </Box>
      <Tabs variant="soft-rounded" colorScheme="green" align="center">
        <TabList>
          <Tab><Text fontSize='xl'>Overview</Text></Tab>
          <Tab><Text fontSize='xl'>Contact</Text></Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <RenderInfo />
          </TabPanel>
          <TabPanel>
            <TextOnImage />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <style jsx>
        {`
          @import url('https://fonts.googleapis.com/css?family=Lobster');
          @import url('https://fonts.googleapis.com/css?family=Roboto');
        `}
      </style>
    </VStack>
  );
}

export default About;
